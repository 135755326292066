<template>
    <div>
        <div class="mt-5 col-span-2 bg-white-pure l-box-shadow rounded px-4 py-3">
            <EpisodeHeader
                :episodes="episodes"
                :episode="computedEpisode"
                :tab="'CASE_NOTES'"
                @create-case-note="createCaseNote"
                @episode-changed="episodeChanged"
            />
        </div>
        <div class="mt-5 col-span-2 bg-white-pure l-box-shadow rounded px-4 py-3">
            <div class="flex flex-row justify-between pb-4 items-center">
                <div class="flex items-center mb-4">
                    <div class="mr-2">
                        <fa-icon icon="filter" class="text-sm text-black" />
                    </div>
                    <v-checkbox
                        hide-details
                        class="mt-0 mr-4"
                        :ripple="false"
                        label="Treatment Events"
                        v-model="hasTreatmentEvents"
                        @change="clearFilters"
                    ></v-checkbox>

                    <v-checkbox
                        hide-details
                        class="mt-0"
                        :ripple="false"
                        label="Behaviour Incidents"
                        v-model="hasBehaviourIncidents"
                        @change="clearFilters"
                    ></v-checkbox>

                </div>
                <v-btn
                    v-if="$can('export', 'case_note')"
                    depressed
                    class="ml-2"
                    color="primary"
                    @click="downloadPdf"
                >
                    <fa-icon icon="download" class="mr-1 text-xs" />
                    <span>Export</span>
                </v-btn>
            </div>
            <CaseNoteTable :tableData="caseNotes" :users="users" />
        </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import camelcaseKeys from 'camelcase-keys'
import CaseNoteTable from '@/components/case-notes/partials/CaseNoteTable'
import GET_EPISODES_FOR_CLIENT from '@/graphql/queries/getEpisodesForClient.gql'
import EpisodeHeader from '@/components/episodes/partials/EpisodeHeader'
import { ClientHelpers } from '@/components/shared/mixins/clientMixins'
import GET_CLIENT from '@/graphql/queries/getClient.gql'
import GET_CASE_NOTES_FOR_EPISODE from '@/graphql/queries/getCaseNotesForEpisode.gql'
import GET_USERS from '@/graphql/queries/getUsers.gql'
import { mapState } from 'vuex'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { AODW_ROLES } from '@/utils/constants'
import { useModalStore } from '@/stores/useModalStore'

const { useActions } = createNamespacedHelpers('')
const { useState } = createNamespacedHelpers('app')

export default {
    name: 'CaseNotes',
    components: { CaseNoteTable, EpisodeHeader },
    mixins: [ ClientHelpers ],
    apollo: {
        episodes: {
            query: GET_EPISODES_FOR_CLIENT,
            variables() {
                return {
                    clientId: Number(this.clientId)
                }
            },
            skip() {
                return this.clientId === null
            },
            update: ({ episodes }) => {
                return camelcaseKeys(episodes, {deep: true})
            },
        },
        client: {
            query: GET_CLIENT,
            variables() {
                return {
                    clientId: Number(this.clientId)
                }
            },
            skip() {
                return this.clientId === null
            },
            update: ({ client }) => {
                return camelcaseKeys(client, {deep: true})
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            }
        },
        caseNotes: {
            query: GET_CASE_NOTES_FOR_EPISODE,
            variables() {
                return {
                    episodeId: Number(this.currentEpisode.episodeId),
                    hasTreatmentEventsCompare: this.hasTreatmentEvents ? {_eq: this.hasTreatmentEvents} : {},
                    hasBehaviourIncidentsCompare: this.hasBehaviourIncidents ? {_eq: this.hasBehaviourIncidents} : {},
                    typeFilter: AODW_ROLES.indexOf(String(this.loggedInUser.role).toLowerCase()) > -1 ? {_neq : "COUNSELLING_NOTE"}: {}
                }
            },
            skip() {
                return this.currentEpisode == null || this.currentEpisode.episodeId == null
            },
            update: ({ caseNotes }) => {
                caseNotes = camelcaseKeys(caseNotes, {deep: true})
                caseNotes.forEach(caseNote => {
                    if (caseNote.treatmentEvents) {
                        caseNote.treatmentEvents = caseNote.treatmentEvents.map(({event}) => event)
                    }
                })

                return caseNotes
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            }
        },
        users: {
            query: GET_USERS,
            update: ({ users }) => {
                return camelcaseKeys(users, {deep: true})
            },
        }
    },
    data() {
        return {
            clientId: this.$route.params.clientId,
            client: {},
            episodes: [],
            caseNotes: [],
            users:[],
            hasTreatmentEvents: null,
            hasBehaviourIncidents: null
        }
    },
    mounted() {
        this.$store.commit('SET_CLIENT_ID', this.clientId)
        this.$root.$on('create-case-note', this.createCaseNote)
    },
    methods: {
        createCaseNote(caseNote) {
            let payload = {
                name: this.getFullName(this.client),
                caseNote: {
                    episodeId: this.currentEpisode.episodeId,
                },
                episodes: this.episodes,
                keepOpen: true,
            }
            if (caseNote) payload.caseNote = {...caseNote}
            this.modalStore.openModal({
                modalName: 'ModalAddCaseNote',
                payload
            })
            // this.$store.commit('MODAL_IS_VISIBLE', true)
            // this.$store.commit('MODAL_DATA', payload)
            // this.$store.commit('MODAL_COMPONENT_NAME', 'ModalAddCaseNote')
        },
        episodeChanged(episode) {
            // this.currentEpisode = {...episode}
            this.resetFilters()
            this.$store.commit('SET_CURRENT_EPISODE', episode)
            this.$apollo.queries.caseNotes.refetch()
        },
        resetFilters() {
            this.hasBehaviourIncidents = null
            this.hasTreatmentEvents = null
        },
        clearFilters(e) {
            // if none of the filters has a value, set to null since that's the only way to get every case note
            if (!this.hasBehaviourIncidents) this.hasBehaviourIncidents = null
            if (!this.hasTreatmentEvents) this.hasTreatmentEvents = null
        }
    },
    computed: {
        ...mapStores(useModalStore),
        ...mapState({
            loggedInUser: state => state.app.loggedInUser,
            currentEpisode: state => state.app.currentEpisode
        }),
        computedEpisode() {
            return this.currentEpisode || {}
        },
    },
    watch: {
        currentEpisode() {
            console.log('episode changed to', this.currentEpisode)
        },
        '$route.path': function(val, oldVal){
            this.$apollo.queries.client.refetch()
        }
    },
    destroyed() {
        this.$root.$off('create-case-note')
    },
    setup() {
        const { openModal } = useActions(['openModal'])
        const { currentEpisode } = useState(['currentEpisode'])

        const downloadPdf = () => {
            openModal({
                modalName: 'ModalExportCaseNotes',
                payload: {
                    episodeId: currentEpisode.value.episodeId
                }
            })
        }

        return { downloadPdf }
    }
}
</script>
